import { createRouter, createWebHashHistory } from "vue-router";
import Home from "../views/Home";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/About.vue"),
  },
  {
    path: "/faqs",
    name: "FAQs",
    component: () => import("../views/FAQs.vue"),
  },

  {
    path: "/mechanics",
    name: "Mechanics",
    component: () => import("../views/Mechanics.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
