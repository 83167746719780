<template>

<div class="app-bg">
    <img
    style="top: 506px; position: absolute; "
      src="../../assets/images/2024/Home/2.png"
      aria-hidden="true"
    />
    <img
      class = "right-block"
      style="top: 700px; position: absolute; "
      src="../../assets/images/2024/Home/3.png"
      aria-hidden="true"
    />
    <img
        style="top: 700px; position: absolute; height:1387px;"
        src="../../assets/images/2024/About/shadow-2.png"
        aria-hidden="true"
      />
</div>

  <section class="hero-section">
    <div class="hero-heading">
      <h3>March-April Ateneo de Manila University</h3>
      <h1>IM SUMMIT 2024</h1>
      <div class="hero-register">
        <p style="color:#7140FD; padding-right:5px;">Early bird deadline is until March 19! </p>
        <div class="hero-register-link">
          <p style="color:#7140FD"><a href="https://forms.gle/1QfH5UYjqLJGFM9v5" target="_blank">Register Now</a></p>
        </div>
      </div>
    </div>
    <div class="hero-copy">
      <div class="hero-text">
        <p>
          Gain knowledge and dive into the opportunity to propose impactful
          and purposeful innovations to industry leaders. 
        </p>
      </div>
    </div>
  </section>

</template>

<style scoped>
.app-bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  min-height: 100%;
  background: #FFFFFF;
  background-repeat: no-repeat;
  overflow: hidden;
}


.right-block {
  position: absolute; /* Make image positioned relative to its container */
  transform: translateY(-50%); /* Offset the top position upwards by half its own height (for centering) */
  right: 0; /* Position the right edge of the image at 0 (rightmost side) */
}

.hero-section {
  padding-top: 5rem;
  color: var(--midnight-purple);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.hero-heading {
  max-width: 600px;
  position: relative;
  margin-bottom: 100px;
  text-align: center;
  justify-content: center;
}

.hero-heading h1 {
  font-size: 100px;
  font-weight: 800;
  line-height: 1;
  margin-bottom: 20px;
}

.hero-heading h3 {
  margin-bottom: 20px;
}

.hero-register {
  width: 400px; /* Adjust width as needed */
  height: 50px; /* Adjust height as needed */
  background-color: #F7F5FF;
  border-radius: 40px 40px; /* Set different radius values for horizontal and vertical */
  display: flex; /* Enable flexbox layout */
  justify-content: center; /* Center content horizontally */
  align-items: center;
  margin: 0 auto;
  border:2px solid #D0BEFF;
  font-size: 15px;
  font-weight: 500;
}

.hero-register-link {
  width: 100px; /* Adjust width as needed */
  height: 35px; /* Adjust height as needed */
  background-color: white;
  border-radius: 40px 40px; /* Set different radius values for horizontal and vertical */
  padding-top:1.5%;
  border:2px solid #D0BEFF;
  font-size: 12px;
  font-weight: 500;
}

.hero-copy {
  width: 50%; 
  margin: 0 auto;
  padding-top: 20rem;
  max-width: 1000px;
  position: relative;
  margin-bottom: 100px;
}

.hero-text p {
  color: black;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 2.5rem; /* Adjust font size as needed */
  text-align: center; /* Center text */
}

.hero-copy > h2 {
  font-weight: 600;
  font-size: 120px;
  line-height: 1;
}

.hero-copy > p {
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: 1.375rem;
}

@media (max-width: 900px) {
  img {
    display: none;
  }
}

@media (max-width: 480px) {
  img {
    display: none;
  }

  .hero-heading h1 {
    font-size: 70px;
    font-weight: 800;
    line-height: 1;
  }

  .hero-register {
    flex-direction: column;
    height: 75px;
    width:325px;
  }
  .hero-register-link {
    padding-bottom:10px;
  }

  .hero-text p {
    font-size: 20px;
  }
}
</style>
