<script setup>
import ButtonLink from "./components/ButtonLink.vue";
import Link from "./components/Link.vue";
import { reactive } from "vue";

const state = reactive({
  menuOpen: false,
});

const toggleMenu = () => {
  state.menuOpen = !state.menuOpen;
};
</script>

<template>
  <div class="nav-container">
    <div class="nav">
      
      <nav class="nav-links">
        <Link to="/about">ABOUT</Link>
        <Link to="/mechanics">MECHANICS</Link>
        <Link to="/faqs">FAQs</Link>
      </nav>

      <div class="logo">
        <a href="/"
          ><img
            src="./assets/logos/summit2024.png"
            alt=""
            style="margin-left: auto;
            margin-right: 200px;
            height:50px;
            width:50px;"
          />
          </a>
      </div>

      <button class="hamburger" @click="toggleMenu">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <path d="M4 6l16 0"></path>
          <path d="M4 12l16 0"></path>
          <path d="M4 18l16 0"></path>
        </svg>  
      </button>

      <nav class="nav-links">
        <ButtonLink href="https://forms.gle/1QfH5UYjqLJGFM9v5" external="True">Register Now</ButtonLink>
      </nav>

    </div>
  </div>

  <!-- for mobile -->
  <div
    class="mobile-nav"
    :class="{ 'mobile-nav--active': state.menuOpen }"
    @click="toggleMenu"
  >
    <nav class="nav-links">
      <Link to="/about">ABOUT</Link>
      <Link to="/mechanics">MECHANICS</Link>
      <Link to="/faqs">FAQs</Link>
      <Link href="https://forms.gle/1QfH5UYjqLJGFM9v5" external="True">APPLY</Link>
    </nav>   
  </div>

  <div class="router-view">
    <router-view />
  </div>

  <!-- Footer -->
  <div class="footer-container">
    <footer>
      <div class="footer-links">
        <!-- facebook -->
        <a
          href="https://www.facebook.com/Ateneo.MISA/"
          target="_blank"
          rel="noreferrer noopener"
        >
          <svg
            width="38"
            height="38"
            viewBox="0 0 38 38"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_452_1127)">
              <path
                d="M34.8334 18.9998C34.8334 10.2598 27.74 3.1665 19 3.1665C10.26 3.1665 3.16669 10.2598 3.16669 18.9998C3.16669 26.6632 8.61335 33.044 15.8334 34.5165V23.7498H12.6667V18.9998H15.8334V15.0415C15.8334 11.9857 18.3192 9.49984 21.375 9.49984H25.3334V14.2498H22.1667C21.2959 14.2498 20.5834 14.9623 20.5834 15.8332V18.9998H25.3334V23.7498H20.5834V34.754C28.5792 33.9623 34.8334 27.2173 34.8334 18.9998Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_452_1127">
                <rect width="38" height="38" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </a>
        <!-- twitter -->
        <a
          href="https://twitter.com/AteneoMISA/"
          target="_blank"
          rel="noreferrer noopener"
        >
          <svg
            width="38"
            height="38"
            viewBox="0 0 38 38"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M35.4666 8.78707C34.2544 9.32541 32.9523 9.68767 31.5856 9.85107C32.9814 9.01507 34.0518 7.69141 34.5559 6.11314C33.2512 6.88707 31.8047 7.44947 30.2644 7.75347C29.032 6.43994 27.2764 5.61914 25.3333 5.61914C21.6017 5.61914 18.5769 8.64521 18.5769 12.3755C18.5769 12.905 18.6377 13.4218 18.7517 13.9145C13.1366 13.6333 8.15858 10.9429 4.82471 6.85414C4.24458 7.85227 3.91144 9.01254 3.91144 10.2526C3.91144 12.5959 5.10338 14.6644 6.91598 15.8753C5.80891 15.8399 4.76644 15.5359 3.85571 15.0305C3.85571 15.0596 3.85571 15.0862 3.85571 15.1153C3.85571 18.3897 6.18384 21.1206 9.27578 21.74C8.70958 21.8945 8.11171 21.9769 7.49484 21.9769C7.06038 21.9769 6.63604 21.9338 6.22438 21.8565C7.08444 24.5406 9.57978 26.4951 12.5362 26.5495C10.2245 28.3621 7.31118 29.4426 4.14451 29.4426C3.59984 29.4426 3.06151 29.4109 2.53204 29.3476C5.52264 31.2641 9.07311 32.3825 12.8896 32.3825C25.3181 32.3825 32.1125 22.0871 32.1125 13.1583C32.1125 12.8657 32.1062 12.5744 32.0935 12.2843C33.4146 11.3305 34.561 10.1411 35.4666 8.78707Z"
              fill="white"
            />
          </svg>
        </a>
        <!-- instagram -->
        <a
          href="https://www.instagram.com/ateneomisa/"
          target="_blank"
          rel="noreferrer noopener"
        >
          <svg
            width="38"
            height="38"
            viewBox="0 0 38 38"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19 13.9331C17.6563 13.9331 16.3675 14.4669 15.4173 15.4171C14.4672 16.3673 13.9333 17.656 13.9333 18.9998C13.9333 20.3435 14.4672 21.6323 15.4173 22.5824C16.3675 23.5326 17.6563 24.0664 19 24.0664C20.3438 24.0664 21.6325 23.5326 22.5827 22.5824C23.5329 21.6323 24.0667 20.3435 24.0667 18.9998C24.0667 17.656 23.5329 16.3673 22.5827 15.4171C21.6325 14.4669 20.3438 13.9331 19 13.9331V13.9331Z"
              fill="white"
            />
            <path
              d="M25.3321 3.7998H12.6654C7.77732 3.7998 3.79999 7.77841 3.79999 12.6677V25.3344C3.79999 30.2225 7.77859 34.1998 12.6679 34.1998H25.3346C30.2227 34.1998 34.2 30.2212 34.2 25.3319V12.6652C34.2 7.77714 30.2214 3.7998 25.3321 3.7998ZM19 26.5998C14.8086 26.5998 11.4 23.1912 11.4 18.9998C11.4 14.8084 14.8086 11.3998 19 11.3998C23.1914 11.3998 26.6 14.8084 26.6 18.9998C26.6 23.1912 23.1914 26.5998 19 26.5998ZM27.8667 11.3998C27.1675 11.3998 26.6 10.8323 26.6 10.1331C26.6 9.43394 27.1675 8.86647 27.8667 8.86647C28.5659 8.86647 29.1333 9.43394 29.1333 10.1331C29.1333 10.8323 28.5659 11.3998 27.8667 11.3998Z"
              fill="white"
            />
          </svg>
        </a>
      </div>

      <div class="logo-container">
        <a href="https://misa.org.ph/"
          ><img src="./assets/images/2023/white-wide-primary-logo.png" alt=""
        /></a>
      </div>
    </footer>
  </div>
</template>

<style>
#app {
  position: relative;
}

.nav-container {
  position: sticky;
  top: 0;
  background-color: white;
  padding: 1rem;
  z-index: 1000;
  box-shadow: 0 2px 3px -2px gray;

}

.nav {
  max-width: var(--container-width);
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  margin-inline: auto;
}

.logo {
  font-family: var(--font-header);
  display: block;
  align-items: center;
  /* margin-left: 200px;
  margin-right: auto; */
}

.logo > span {
  font-size: 1.5rem;
  color: var(--white);
}

.hamburger {
  display: none;
  color: var(--black);
}

.mobile-nav {
  touch-action: none;
  opacity: 0;
  max-height: 0;
  transition-property: opacity, max-height;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}

.mobile-nav--active {
  touch-action: auto;
  opacity: 1;
  max-height: 500px;
}

.mobile-nav {
  touch-action: none;
  opacity: 0;
  max-height: 0;
  transition-property: opacity, max-height;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}

.mobile-nav--active {
  touch-action: auto;
  opacity: 1;
  max-height: 500px;
}

.nav-links {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 1rem;
  /* width: 40%; min-width: 430px; */
}

.nav-links > a {
  font-size: 1rem;
  font-weight: 500;
}

.router-view {
  /* padding-bottom: 4rem; */
}

.footer-container {
  z-index: 1;
  position: relative;
  padding: 1rem;
  background-color: var(--black);
}

footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: var(--container-width);
  margin-inline: auto;
}

footer .footer-links {
  display: flex;
  gap: 2rem;
  align-items: center;
}

@media (max-width: 768px) {
  .nav-links {
    display: none;
    padding-bottom: 2rem;
    min-width: 0;
  }

  .mobile-nav .nav-links {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    justify-content: center;
    margin-inline: auto;
  }

  .hamburger {
    display: block;
    background: v(--white)
  }

  .footer-links svg {
    height: 20px;
    width: 20px;
  }

  .logo-container img {
    width: 120px;
  }

  .footer-links svg {
    height: 20px;
    width: 20px;
  }

  .logo-container img {
    width: 120px;
  }
}
</style>
